<template>
    <v-sheet>
        <c-item-editor-form :title="$t('battery-model-editor.view.title', { name: name })" :value="batteryModel"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole" :hideSaveAction="!hasEditRole">
            <template v-slot:default="props">

                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="navigation.selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('battery-model-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('battery-model-editor.view.tabs.batterydata') }}</v-tab>
                        <v-tab :key="2">{{ $t('battery-model-editor.view.tabs.batteryConformity') }}</v-tab>
                        <v-tab :key="3">{{ $t('battery-model-editor.view.tabs.batterymaterialcomposition') }}</v-tab>
                        <v-tab :key="4">{{ $t('battery-model-editor.view.tabs.safety') }}</v-tab>
                        <v-tab :key="5">{{ $t('battery-model-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="navigation.selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batteryModel" :disabled="!props.editable" />
                            </v-tab-item>

                            <!-- Settings -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('battery-model-editor.view.tabs.batterydata.title') }}
                                        </div>
                                        <div>{{ $t('battery-model-editor.view.tabs.batterydata.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("battery-model-editor.view.sections.batterydata.title") }}
                                        </div>
                                        <div>
                                            {{ $t('battery-model-editor.view.sections.batterydata.subtitle') }}
                                        </div>
                                        <p></p>
                                        <c-item-selector 
                                            v-model="batteryCategoryId" 
                                            :items="batteryCategories"
                                            :disabled="!props.editable" 
                                            :return-object="false"
                                            :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.category.valuecheck')]"
                                            :label="$t('battery-model-editor.view.category')" 
                                            filled 
                                        />


                                        <c-item-selector 
                                            v-model="manufacturerId" 
                                            :items="manufacturers"
                                            :disabled="!props.editable" 
                                            :return-object="false"
                                            :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.manufacturer.valuecheck')]"
                                            :label="$t('battery-model-editor.view.manufactuerer')" 
                                            filled
                                        />
                                        
                                        <c-numeric-input 
                                            v-model="batteryModel.data.batteryWeight" 
                                            color="accent" 
                                            :disabled="!props.editable"
                                            :label="$t('battery-model-editor.view.weight.text')" 
                                            filled 
                                        />


                                        <c-item-selector 
                                            v-model="batteryChemistryId" 
                                            :items="batteryChemistries" 
                                            color="accent" 
                                            :disabled="!props.editable" 
                                            :return-object="false"
                                            :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.chemistry.valuecheck')]"
                                            :label="$t('battery-model-editor.view.tabs.chemistry.text')" 
                                            filled 
                                        />

                                    </v-card-text>


                                </v-card>
                            </v-tab-item>

                            <v-tab-item :key="2">
                                <v-container fluid>

                                    <dxs-conformity-editor-page v-model="conformityEditorPageModel"
                                        :disabled="!props.editable" />

                                    <v-card flat>
                                        <v-card-text>
                                            <c-string-value-editor headerStyle="lite" :items="testReports"
                                                @item-removed="handleItemRemoved" @item-updated="handleItemUpdated"
                                                :editor-description="$t('string-value.component.editordescription.text')"
                                                :editor-title="$t('string-value.component.editortext')"
                                                text-field-label="Link"
                                                :text-field-header="$t('string-value.component.header.text')"
                                                :title="$t('string-value.component.title')"
                                                :disabled="!props.editable"></c-string-value-editor>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            </v-tab-item>
                            <!--COMPOSITION-->
                            <v-tab-item :key="3">
                                <v-container fluid>
                                    <v-card flat>
                                        <v-card-text>
                                            <dxs-battery-material-composition-editor :items="compositionItems"
                                                :battery-materials="availableBatteryMaterials"
                                                @material-removed="handleMaterialRemoved" 
                                                @material-updated="handleMaterialUpdated"
                                                :disabled="!props.editable">
                                            </dxs-battery-material-composition-editor>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            </v-tab-item>
                            <v-tab-item :key="4"> 
                                <c-paginated-item-list-page
                                @item-deleted="onSafetyAssignmentDelete"
                                @item-edited="onSafetyAssignmentEdited"
                                @item-added="onSafetyAssignmentAdded"
                                :disableEditItem = "true"
                                :disabled="!hasEditRole"
                                :hideReload="!hasInstructionsReadRole"
                                :readonly="!hasEditRole"
                                :hideProgress="!hasInstructionsReadRole"
                                :supportsEditItem = "false"
                                @load-more = "onLoadMoreAssignments"
                                hide-name-column
                                hide-i18n-name-column
                                :items = "assignment.items"
                                :pagination = "assignment.pagination"
                                :title="title"
                                :subtitle="subtitle"
                                :pageSize=25>
                                    <template v-slot:paginated-item-list-page-table-head-columns>
                                        <th> {{ $t('battery-model-editor.view.safetyinstruction.table.column.name') }} </th>
                                    </template>
                                    <template v-slot:paginated-item-list-page-table-body-columns="{item}">
                                        <td> {{ item.references.safetyinstructionid }}</td>
                                    </template>
                                    <template v-slot:list-item-title="{item}">
                                        <th> {{ item.references.safetyinstructionid }} </th>
                                    </template>
                                    <template v-slot:list-item-subtitle>
                                        <td> {{ $t('battery-model-editor.view.safetyinstruction.table.column.name') }} </td>
                                    </template>
                                </c-paginated-item-list-page>      
                                </v-tab-item>
                            <!-- JSON -->
                            <v-tab-item :key="5">
                                <c-json-item-editor-page v-model="batteryModel" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';

import ConformityEditorPageComponent from '@/solutions/battery-data-hub/components/masterdata/battery-models/conformity-editor-page.component.vue';
import BatteryMaterialCompositionEditor from '@/solutions/battery-data-hub/components/material-composition/battery-material-composition-editor.component.vue';
import BatteryModelEditorModule from "../../../store/masterdata/battery-models/battery-model-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'battery-data-hub:masterdata:battery-models:batteryModelEditor';

export default {
    name: 'battery-model-editor',
    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-conformity-editor-page': ConformityEditorPageComponent,
        'dxs-battery-material-composition-editor': BatteryMaterialCompositionEditor

    },

    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            model: {
                euDeclarationOfConformityId: this.euDeclarationOfConformityId,
                euDeclarationOfConformityLink: this.euDeclerationOfConformity,

            },
            navigation: {
                selectedTab: this.$route.query.tabIndex ? parseInt(this.$route.query.tabIndex):0
            },
            changedIndexList: []




        }
    },

    computed: {
        title(){
            return this.$t('assignment-list.view.toolbar.title');
        },
        subtitle(){
            return this.$t('assignment-list.view.toolbar.subtitle');
        },
        assignment: {
            get(){
                return this.$store.getters[MODULE_ID + '/assignment'];
            }},

        conformityEditorPageModel: {

            get() {
                return {
                    euDeclarationOfConformityId: this.idOfEuDeclerationOfConformity,
                    euDeclarationOfConformityLink: this.euDeclerationOfConformity
                }
            },
            set(value) {
                this.idOfEuDeclerationOfConformity = value.euDeclarationOfConformityId;
                this.euDeclerationOfConformity = value.euDeclarationOfConformityLink;
            }
        },

        batteryModel: {
            get() {
                return this.$store.getters[MODULE_ID + '/batteryModel'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryModel", value);
            }
        },

        batteryWeight: {
            get() {
                return this.$dxs.formatting.formatDecimal(this.$store.getters[MODULE_ID + '/batteryWeight'], this.$i18n.locale);
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryWeight", value);
            }
        },
        
        batteryChemistries:{
            get(){
                return this.$store.getters[MODULE_ID + '/batteryChemistries']
            }
        },

        idOfEuDeclerationOfConformity: {
            get() {
                return this.$store.getters[MODULE_ID + '/idOfEuDeclerationOfConformity'];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateIdOfEuDeclerationOfConformity", value);
            }
        },

        euDeclerationOfConformity: {
            get() {

                return this.$store.getters[MODULE_ID + '/euDeclerationOfConformity'];
            },
            set(value) {

                this.$store.dispatch(MODULE_ID + "/updateEuDeclerationOfConformity", value);
            }
        },

        testReports: {
            get() {

                return this.$store.getters[MODULE_ID + '/testReports'];

            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateTestReports", value);
            }
        },
        batteryMaterialCompositionItems: {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryMaterialCompositionItems'];

            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialCompositions", value);
            }

        },
        compositionItems: {
            get() {
                let flatenedMaterialCompositions = [];
                this.batteryMaterialCompositionItems.forEach(item => {
                    flatenedMaterialCompositions.push({
                        share: get(item, 'data.share', null),
                        batterymaterialId: get(item, 'references.batterymaterialId', null),
                        name: get(item, 'name', null),
                        tenantId: get(item, 'tenantId', null)
                    });
                });
                return flatenedMaterialCompositions;
            },

        },
        addassign:{
            get(){
                return true;
            }
        },
        
        availableBatteryMaterials: {
            get() {

                return this.$store.getters[MODULE_ID + '/availableBatteryMaterials'];
            }
        },
        
        batteryCategories: {
            get() {
                return this.$store.getters[MODULE_ID + "/batteryCategories"];
            }
        },

        batteryCategory: {
            
            get() {
                return this.$store.getters[MODULE_ID + "/batteryCategory"];
            },

            set(value) {
                this.$store
                    .dispatch(MODULE_ID + "/updateBatteryCategory", value)
                    .catch(this.errorHandlerService.handleError);
            }
        },

        manufacturer: {

            get() {
                return this.$store.getters[MODULE_ID + "/manufacturer"];
            },

            set(item) {
                this.$store
                .commit(MODULE_ID + "/updateManufacturer", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        manufacturers: {
            get() {
                return this.$store.getters[MODULE_ID + "/manufacturers"];
            }
        },


        name() {
            return get(this.value, 'name');
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYMODEL_EDIT);
        },
        hasInstructionsReadRole() {
            return this.userContextService.hasRole(Roles.SECURITYINSTRUCTIONS_READ);
        },
        batteryCategoryId:{
            get(){
                return this.$store.getters[MODULE_ID + '/batteryCategoryId'];
            },
            set(value){
                this.$store.dispatch(MODULE_ID + '/updateBatteryCategoryId', value);
            }
        },
        manufacturerId: {
            get(){
                return this.$store.getters[MODULE_ID + '/manufacturerId'];
            },
            set(value){
                this.$store.dispatch(MODULE_ID + '/updateManufacturerId', value);
            }
        },
        batteryChemistryId: {
            get() {
                return this.$store.getters[MODULE_ID + '/batteryChemistry'];
             },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryChemistryId", value);
            }
        },
    },

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                this.$log.debug(`Loading information for battery model ${this.name}`);
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        onSafetyAssignmentEdited(event) {
            if (event) {
                const route = this.$router.resolve({
                    name: "SafetyInstructionEditorView",
                    params: {
                        name: event.item.name,
                    }
                });
                
        window.open(route.href, '_blank');
            } else {
                this.$log.debug(
                    "Event object is undefined. Can't navigate to prediction scenario."
                );
            }
        },

        async onLoadMoreAssignments(payload) {
            
            this.progressIndicatorService.show();
            try{
            await this.$store.dispatch(MODULE_ID +'/loadMoreAssignments', {pagination: payload.pagination});
            }catch(error){
                this.errorHandlerService(error);
            }finally{
            this.progressIndicatorService.hide();
            }
        },

        onSafetyAssignmentAdded() {
                this.$router.push({
                    name: "AssignmentWizardView",
                });
        },

        async onSafetyAssignmentDelete({ item }) {
            this.progressIndicatorService.show();
            try {
                console.log(item);
                await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {


                
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData", { name: this.name });
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        handleItemUpdated({ item, index }) {
            const updatedTestReports = [...this.testReports];
            updatedTestReports.splice(index, 1, item);
            this.testReports = updatedTestReports;
        },
        handleItemRemoved({ index }) {
            const updatedTestReports = [...this.testReports];
            updatedTestReports.splice(index, 1);
            this.testReports = updatedTestReports;
        },
        
        handleMaterialRemoved(event) {
            // Clone the available compositions into a new list
            const compositions = [...this.batteryMaterialCompositionItems];
            // Filter the compositions after the batteryMaterialId. It is the only really comparable item of the given event.
            const filteredCompositions = compositions.filter(item => item.references.batterymaterialId !== event.item.batterymaterialId);
            // Update the compositions
            this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialCompositions", filteredCompositions);
            // Add the deleted material to the select-list to appear in the filtered material-select list again.
            this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialList");
        },

        handleMaterialUpdated(event) {
            const compositions = [...this.batteryMaterialCompositionItems];
            const itemToUpdate = compositions.find((item) => item.references.batterymaterialId === event.item.batterymaterialId);
            if(itemToUpdate) {
                itemToUpdate.data.share = event.item.share;
            } else {
                const newItem = this.createNewBatteryMaterial(event.item);
                compositions.push(newItem);
            }
            this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialCompositions", compositions);
            this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialList");
        },


        createNewBatteryMaterial(valueItem) {
           return {
                "creationDateTime": null,
                "creationUser": null,
                "modificationDateTime": null,
                "modificationUser": null,
                "active": true,
                "data": {
                    "share": valueItem.share
                },
                "i18n": {},
                "name": "",
                "references": {
                    "batterymaterialId": valueItem.batterymaterialId,
                    "batterymodelId": this.name
                }, "tenantId": valueItem.tenantId
            };

        },


        cancel() {
            this.$router.push({
                name: "BatteryModelListView",
            });
        }
    },
    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryModelEditorModule);
        }

        this.refresh();

    },
    beforeMount() {
        if (this.$store.hasModule(MODULE_ID)) {
             this.$store.unregisterModule(MODULE_ID);
             this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
         }
        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryModelEditorModule);
        }

        this.refresh();

    },

    destroyed() {
        // Unregister the vuex store if it has been registered before.
         if (this.$store.hasModule(MODULE_ID)) {
             this.$store.unregisterModule(MODULE_ID);
             this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
         }
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-model-editor.view.tabs.batterydata.title": "Settings",
        "battery-model-editor.view.sections.batterydata.title": "Battery Data",
        "battery-model-editor.view.tabs.batterydata.subtitle" : "This page contains settings for the selected Battery Model.",
        "battery-model-editor.view.sections.batterydata.subtitle": "Please enter battery data such as manufacturer, weight or chemistry here.",
        "string-value.component.title" : "Links to the Test Reports",
        "battery-model-editor.view.title" : "Battery Model {name}",
        "battery-model-editor.view.tabs.general" : "General",
        "battery-model-editor.view.tabs.batteryConformity" : "Battery Data",
        "battery-model-editor.view.tabs.batteryConformity" : "Conformity",
        "battery-model-editor.view.tabs.json" : "JSON",
        "battery-model-editor.view.weight.text" : "Battery Weight in kg",
        "battery-model-editor.view.tabs.batterydata" : "Settings",
        "battery-model-editor.view.tabs.chemistry.text" :"Battery Chemistry",
       "string-value.component.editordescription.text": "Here you can enter the Link to the Test Report.",
       "string-value.component.editortext" : "Link to the Test Report",
       "string-value.component.header.text": "Edit Link",
       "battery-model-editor.view.tabs.batterymaterialcomposition": "Material",
       "battery-model-editor.view.category.valuecheck": "Please assign a Battery Category.",
       "battery-model-editor.view.category":"Battery Category",
       "battery-model-editor.view.manufactuerer":"Manufacturer",
       "battery-model-editor.view.manufacturer.valuecheck" : "Please assign a Manufacturer.",
       "battery-model-editor.view.chemistry.valuecheck" : "Please assign a Battery Chemistry.",
        "assignment-list.view.toolbar.title" : "Safety Instructions",
        "assignment-list.view.toolbar.subtitle" : "Here you can manage the Safety Instructions belonging to this Model. You can create, delete and edit Safety Instructions.",
        "battery-model-editor.view.tabs.safety": "Safety Instructions",
        "battery-model-editor.view.safetyinstruction.table.column.name" : "Safety Instruction"
    },

    "de" : {
        "battery-model-editor.view.tabs.batterydata.title": "Einstellungen",
        "battery-model-editor.view.sections.batterydata.title": "Batteriedaten",
        "battery-model-editor.view.tabs.batterydata.subtitle" : "Diese Seite enthält Einstellungen zum ausgewählten Batterie-Modell.",
        "battery-model-editor.view.sections.batterydata.subtitle": "Bitte weisen Sie hier Daten zur Batterie, wie Hersteller, Gewicht oder Chemie zu.",
        "battery-model-editor.view.title" : "Batterie-Modell {name}",
        "string-value.component.title" : "Links zu den Testberichten",
        "battery-model-editor.view.tabs.general" : "Allgemein",
        "battery-model-editor.view.tabs.batterydata" : "Einstellungen",
        "battery-model-editor.view.tabs.batteryConformity" : "Konformität",
        "battery-model-editor.view.weight.text" : "Batteriegewicht in kg",
        "battery-model-editor.view.tabs.chemistry.text" :"Batterie-Chemie",
        "string-value.component.editordescription.text": "Hier können Sie den Link zum Testbericht anpassen.",
        "string-value.component.editortext" : "Link zum Testbericht",
        "string-value.component.header.text": "Link bearbeiten",
        "battery-model-editor.view.tabs.batterymaterialcomposition": "Material",
        "battery-model-editor.view.category.valuecheck": "Bitte wählen Sie eine Batterie-Kategorie.",
        "battery-model-editor.view.category":"Batterie-Kategorie",
        "battery-model-editor.view.manufactuerer":"Hersteller",
        "battery-model-editor.view.manufacturer.valuecheck" : "Bitte wählen Sie einen Hersteller.",
        "battery-model-editor.view.chemistry.valuecheck" : "Bitte wählen Sie eine Batterie-Chemie.",
        "assignment-list.view.toolbar.title" : "Sicherheitsanweisungen",
        "assignment-list.view.toolbar.subtitle" : "Hier können Sie die Sicherheitsanweisungen dieses Modells verwalten. Sie können Sicherheitsanweisungen editieren, erstellen, oder löschen.",
        "battery-model-editor.view.tabs.safety": "Sicherheitsanweisungen",
        "battery-model-editor.view.safetyinstruction.table.column.name" : "Sicherheitsanweisung"

    }
}
</i18n>
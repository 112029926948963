import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        tenantId: "",
        batteryChemistries: createEmptyResponse()
    },

    mutations: {


        setBatteryChemistry(state, batteryChemistries) {
            console.log(batteryChemistries)
            console.log("*"*25)
            if (batteryChemistries) {
                state.batteryChemistries.tenantId = batteryChemistries.tenantId ? batteryChemistries.tenantId : null;
                state.batteryChemistries.name = batteryChemistries.name ? batteryChemistries.name : null;
                state.batteryChemistries.i18n = batteryChemistries.i18n ? batteryChemistries.i18n : {};
                state.batteryChemistries.data = batteryChemistries.data ? batteryChemistries.data : { custData: {} };
                state.batteryChemistries.active = batteryChemistries.active ? batteryChemistries.active : true;
                state.batteryChemistries.creationDateTime = batteryChemistries.creationDateTime ? batteryChemistries.creationDateTime : null;
                state.batteryChemistries.creationUser = batteryChemistries.creationUser ? batteryChemistries.creationDateTime : null;
                state.batteryChemistries.modificationDateTime = batteryChemistries.modificationDateTime ? batteryChemistries.modificationDateTime : null;
                state.batteryChemistries.modificationUser = batteryChemistries.modificationUser ? batteryChemistries.modificationUser : null;
            } else {
                state.batteryChemistries.tenantId = null;
                state.batteryChemistries.name = null;
                state.batteryChemistries.i18n = null;
                state.batteryChemistries.data = null;
                state.batteryChemistries.active = null;
                state.batteryChemistries.references = null;
                state.batteryChemistries.creationDateTime = null;
                state.batteryChemistries.modificationDateTime = null;
                state.batteryChemistries.creationUser         = null;
                state.batteryChemistries.modificationUser = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        },

    },

    actions:{
        async updateName(context, name){
            try{
                context.commit('setName',name)
            }catch(error){
                context.commit('setName', null);
            }
        },

        async resetBatteryChemistry(context) {
            context.commit('setBatteryChemistry', createEmptyResponse());
        },

        async createBatteryChemistry(context) {

            try {
                
                let request = context.getters.request;
                console.log(request)
                
                await api.masterdata.batteryChemistries.create(request);
            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async updateBatteryChemistry(context, batteryChemistries) {

            try {
                context.commit('setBatteryChemistry', batteryChemistries);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateBatteryChemistries(context, batteryChemistries) {

            try {
                context.commit('setBatteryChemistry', batteryChemistries);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.batteryChemistries;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batteryChemistries(state) {
            return state.batteryChemistries ? state.batteryChemistries : createEmptyResponse();
        },
        
        batteryChemistry(state) {
            return state.batteryChemistries ? state.batteryChemistries : createEmptyResponse();
        },
        name(state){
            return state.batteryChemistries.name;
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },

    }
}

function createEmptyResponse() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}


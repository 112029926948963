<template>
  <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-base-dialog :value="batteryChemistry" :title="$t('battery-chemistries-wizard.view.title')"
                  :subtitle="$t('battery-chemistries-wizard.view.subtitle')" color="secondary"
                  @dialog-saved="save"
                  @dialog-canceled="cancel">
                  <template v-slot:default="props">
                      <v-card flat>
                          <v-card-text>
                              <v-row>
                                  <v-col cols="12">
                                      <c-name-input v-model="props.item.name" filled />
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                    </template>
              </c-base-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import BatteryChemistriesWizardModule from "../../../store/masterdata/battery-chemistries/battery-chemistries-wizard.module";

const MODULE_NAME = "BatteryChemistriesWizard";

export default {
  name: "battery-chemistries-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();

                try{
                await this.$store.dispatch(MODULE_NAME + "/createBatteryChemistry");
                this.$router.push({
                    name: "BatteryChemistryEditorView",
                    params: { name: this.batteryChemistries.name },});
                    this.reset();
                } catch(error1){
                    this.errorHandlerService.handleError(error1);
                    this.reset();
                } finally {
                    this.progressIndicatorService.hide();
                }
                
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "BatteryChemistryListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetBatteryChemistry");
            this.setTenantId(this.tenantId);
        }

        
  },

  computed: {
batteryChemistries: {
    get() {
        return this.$store.getters[MODULE_NAME + "/batteryChemistry"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatteryChemistries", item)
            .catch(this.errorHandlerService.handleError);
    },
},

batteryChemistry: {
    get() {
        return this.$store.getters[MODULE_NAME + "/batteryChemistry"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatteryChemistries", item)
            .catch(this.errorHandlerService.handleError);
    },
},
tenantId() {
    return this.envContextService.tenantId;
}
},

async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      await this.$store.registerModule(MODULE_NAME, BatteryChemistriesWizardModule);//todo
  }
    await this.$store.dispatch(MODULE_NAME + "/resetBatteryChemistry");//todo
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatteryChemistriesWizardModule);//todo
  }
    await this.$store.dispatch(MODULE_NAME + "/resetBatteryChemistry");//todo
    this.reset();
},


destroyed() {
    this.reset();
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "battery-chemistries-wizard.view.title" : "Create New Battery Chemistry",
        "battery-chemistries-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Chemistry.",
        "battery-chemistry-wizard.view.subheader.errorm" : "This Battery Chemistry already exists."
        
    },

    "de" : {
        "battery-chemistries-wizard.view.title" : "Neues Batterie-Chemie anlegen",
        "battery-chemistries-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Batterie-Chemie.",
        "battery-chemistry-wizard.view.subheader.errorm" : "Dieses Batterie-Chemie existiert bereits."
    }
}
</i18n>
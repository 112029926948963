import { cloneDeep, get, set } from 'lodash';
import api from '../../../api/battery-data-hub.api';

export default {
    namespaced: true,

    state: {
        pagination:{
            from : 0,
            pageSize : 25, 
            totalItemCount: 50},
        safetyAssignments : {
            pagination: {
                from : 0,
                pageSize : 5, 
                totalItemCount: 5},
            items: [],
        },
        response: getDefaultResponse(),
        filter: getDefaultFilter(),
        
        //
        batteryModel: createDefaultBatteryModel(),

        
        // The actual data model of the editor.
        model: {
            batteryChemistry: getDefaultResponse(),
            batterycategoryId: getDefaultResponse(),
            manufacturerId: getDefaultResponse(),
        },
        
        context: {
            manufacturers: getDefaultResponse(),
            chemistries: getDefaultResponse(),
        },

        batteryWeight: null,

        idOfEuDeclerationOfConformity: null,
        euDeclerationOfConformity: null,
        testReports: [],
        batteryMaterialCompositions: {},
        safetyAssignment: {},
        batteryMaterials: [],
        availableBatteryMaterials: [],
        availableSafetyAssignments: [],
        batteryCategories: getDefaultResponse()


        
    },
    mutations: {
        setBatteryModel(state, batteryModel) {
            if (batteryModel) {
                state.batteryModel.creationDateTime = batteryModel.creationDateTime ? batteryModel.creationDateTime : null;
                state.batteryModel.creationUser = batteryModel.creationUser ? batteryModel.creationUser : null;
                state.batteryModel.modificationDateTime = batteryModel.modificationDateTime ? batteryModel.modificationDateTime : null;
                state.batteryModel.modificationUser = batteryModel.modificationUser ? batteryModel.modificationUser : null;
                state.batteryModel.tenantId = batteryModel.tenantId ? batteryModel.tenantId : null;
                state.batteryModel.name = batteryModel.name ? batteryModel.name : null;
                state.batteryModel.i18n = batteryModel.i18n ? batteryModel.i18n : {};
                state.batteryModel.active = batteryModel.active ? batteryModel.active : true;
                state.batteryModel.references.batteryChemistry = batteryModel.references.batteryChemistry ? batteryModel.references.batteryChemistry : "";
                state.batteryModel.references.batterycategoryId = batteryModel.references.batterycategoryId ? batteryModel.references.batterycategoryId : "";
                state.batteryModel.references.manufacturerId = batteryModel.references.manufacturerId ? batteryModel.references.manufacturerId : "";
                state.batteryModel.data =  batteryModel.data ? batteryModel.data : null;
                state.model.batteryChemistry = batteryModel.references.batteryChemistry ? batteryModel.references.batteryChemistry : getDefaultItemResponse();
                state.model.batterycategoryId = batteryModel.references.batterycategoryId ? batteryModel.references.batterycategoryId : getDefaultItemResponse();
                state.model.manufacturerId = batteryModel.references.manufacturerId ? batteryModel.references.manufacturerId : getDefaultItemResponse();
            } else {
                state.batteryModel.creationDateTime = null;
                state.batteryModel.creationUser = null;
                state.batteryModel.modificationDateTime = null;
                state.batteryModel.modificationUser = null;
                state.batteryModel.tenantId = null;
                state.batteryModel.name = null;
                state.batteryModel.i18n = {};
                state.batteryModel.active = true;
                state.batteryModel.references = {};
                state.batteryModel.data =  null;
                state.model.batteryChemistry = getDefaultItemResponse();
                state.model.batterycategoryId = getDefaultItemResponse();
                state.model.manufacturerId = getDefaultItemResponse();
            }

        },

        setBatteryWeight(state, batteryWeight) {

            if (batteryWeight) {
                state.batteryModel.data.batteryWeight = batteryWeight;
            } else {
                state.batteryModel.data.batteryWeight = 0;
            }
        },

        setBatteryChemistry(state, batteryChemistry) {
            console.log("********SET CHEMISTRY***")
            console.log("********SET CHEMISTRY***")
            console.log("********SET CHEMISTRY***")
            console.log(batteryChemistry)
            if (batteryChemistry) {
                state.batteryModel.references.batteryChemistry = batteryChemistry;
                state.model.batteryChemistry = batteryChemistry;
            } else {
                state.model.batteryChemistry = getDefaultItemResponse();
            }
        },

        setIdOfEuDeclerationOfConformity(state, idOfEuDeclerationOfConformity) {
            if (idOfEuDeclerationOfConformity) {
                state.batteryModel.data.idOfEuDeclerationOfConformity = idOfEuDeclerationOfConformity;
            }
        },
        setEuDeclerationOfConformity(state, euDeclerationOfConformity) {
            if (euDeclerationOfConformity) {
                state.batteryModel.data.euDeclerationOfConformity = euDeclerationOfConformity;
            }
        },
        setTestReports(state, testReports) {
            if (testReports) {
                state.batteryModel.data.testReports = testReports ? testReports.map(report => report.value) : [];
            }
            else {
                state.batteryModel.data.testReports = [];
            }
        },
        setBatteryMaterialCompositionItems(state, batteryMaterialCompositionItems) {
            if (batteryMaterialCompositionItems) {
                state.batteryMaterialCompositions.items = batteryMaterialCompositionItems;
            }
        },
        setBatteryMaterialCompositions(state, batteryMaterialCompositions) {
            if (batteryMaterialCompositions) {
                state.batteryMaterialCompositions = batteryMaterialCompositions;

            }
        },
        setBatteryMaterials(state, batteryMaterials) {

            if (batteryMaterials && Array.isArray(batteryMaterials.items)) {
                state.batteryMaterials = batteryMaterials.items;
            } else {
                state.batteryMaterials = [];
            }

        },
        setSafetyAssignments(state, safetyAssignments) {

            if (safetyAssignments && Array.isArray(safetyAssignments.items)) {
                state.safetyAssignments.items = safetyAssignments.items;
            } else {
                state.safetyAssignments.items = [];
            }

        },

        setCategories(state, batteryCategories) {
            if (batteryCategories) {
                let items = [];
                if (batteryCategories.items) {
                    state.batteryCategories = batteryCategories.items;
                } else {
                    state.batteryCategories = items;
                }

                if (batteryCategories.tenantId) {
                    state.batteryCategories.tenantId = batteryCategories.tenantId;
                }

                if (batteryCategories.pagination) {
                    state.batteryCategories.pagination = batteryCategories.pagination;
                }
            }
        },
        setManufacturer(state, manufacturer) {
            state.batteryModel.references.manufacturerId = manufacturer.name ? manufacturer.name = manufacturer : getDefaultItemResponse();
            state.model.manufacturerId = manufacturer.name ? manufacturer.name : getDefaultItemResponse();
        },


        setBatteryCategory(state, batteryCategory) {
            if (batteryCategory) {
                state.batteryModel.references.batterycategoryId = batteryCategory;
            } else {
                state.batteryModel.references.batterycategoryId = null;
            }
        },
        setBatteryMaterialList(state) {

            if (state.batteryMaterialCompositions) {
                const batteryMaterialCompositionItems = get(state, 'batteryMaterialCompositions.items', []);

                const inUseBatteryMaterialIds = batteryMaterialCompositionItems.map(item => get(item, 'references.batterymaterialId'));
                const allBatteryMaterials = get(state, 'batteryMaterials', []);

                const availableBatteryMaterials = allBatteryMaterials.filter(material => !inUseBatteryMaterialIds.includes(material.name));
                state.availableBatteryMaterials = availableBatteryMaterials.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                state.availableBatteryMaterials = [];
            }
        },
        setSafetyAssignmentList(state) {

            if (state.SafetyAssignments) {
                const safetyAssignmentItems = get(state, 'safetyAssignments.items', []);

                const inUseBatteryMaterialIds = safetyAssignmentItems.map(item => get(item, 'references.safetyinstructionid'));
                const allBatteryMaterials = get(state, 'safetyAssignments', []);

                const availableBatteryMaterials = allBatteryMaterials.filter(material => !inUseBatteryMaterialIds.includes(material.name));
                state.availableBatteryMaterials = availableBatteryMaterials.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                state.availableBatteryMaterials = [];
            }
        },
        setResponse(state, response) {
            if (response) {

                let items = [];
                if (response.items) {
                    state.response.items = response.items;
                } else {
                    state.response.items = items;
                }

                if (response.tenantId) {
                    state.response.tenantId = response.tenantId;
                }

                if (response.pagination) {
                    state.response.pagination = response.pagination;
                }

            }

        },
        addAssignments(state, response) {
            const novel = [];
            for (let item of response.items) {
                item.name = get(item, 'references.safetyinstructionid', '');
                novel.push(item);
            }
                state.safetyAssignments.items = novel;
                state.safetyAssignments.pagination = response.pagination;
        },
        setAssignments(state, response) {
            if (response) {
                state.safetyAssignments.items= response.items;
                state.safetyAssignments.pagination = response.pagination || {
                    from: 0,
                    pageSize: 5,
                    totalItemCount: 5
                };
            } else {
                state.safetyAssignments.items = [];
                state.safetyAssignments.pagination = {
                    from: 0,
                    pageSize: 5,
                    totalItemCount: 5
                };
            }
        },
        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter()
            }
        },
        addToDeleteList(state, item) {
            const novelty =[]
            for (let item_assigned of state.safetyAssignments.items) {
                if(item_assigned.references.safetyinstructionid !== item.references.safetyinstructionid){
                    novelty.push(item_assigned);
                }
            }
            state.safetyAssignments.items = novelty;
        },

        setChemistries(state, chemistryResponse){
            if(chemistryResponse){
                state.context.chemistries = chemistryResponse;
            } else {
                state.context.chemistries = getDefaultResponse();
            }
        },

        setManufacturers(state, manufacturerResponse) {
            if(manufacturerResponse){
                state.context.manufacturers = manufacturerResponse;
            } else {
                state.context.manufacturers = getDefaultResponse();
            }
        },
        setBatteryCategoryId(state, item){
            state.model.batterycategoryId = item;
            state.batteryModel.references.batterycategoryId = item;
        },
        setManufacturerId(state, item){
            state.model.manufacturerId = item;
            state.batteryModel.references.manufacturerId = item;
        },
        setBatteryChemistryId(state, item){
            state.model.batteryChemistry = item;
            state.batteryModel.references.batteryChemistry = item;
        }
    },
    actions: {
        
        async loadMoreAssignments(context, {pagination}) {
            const name = context.getters["name"];
            console.log(pagination);
            const response = await api.masterdata.batteryModels.findSafetyAssignmentByBatteryModelId(name,0,150);
              console.log(response);
            context.commit('addAssignments', response);
        },

        async loadData(context, { name }) {

            // Load the battery model
            try{
                let response = await api.masterdata.batteryModels.findById(name);
                context.commit('setBatteryModel',     get(response, 'item.batteryModel',    createDefaultBatteryModel()));
                context.commit('setBatteryChemistry', get(response, 'item.batteryChemistry.name', ""));
                context.commit('setBatteryCategoryId', get(response, 'item.batteryCategory.name', ""));
                context.commit('setManufacturerId',     get(response, 'item.manufacturer.name', ""));
                let batteryMaterials = await api.masterdata.batteryMaterials.findByFilter('*', 0, 100);
                context.commit('setBatteryMaterials', batteryMaterials, []);

            
                
                response = await api.masterdata.batteryChemistries.findByFilter('*', 0, 100);
                context.commit('setChemistries', response);
           
                
                response = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
                context.commit('setManufacturers', response);
            

                let batteryMaterialCompositions = await api.masterdata.batteryModels.findCompositionByBatteryModelId(name);

                context.commit('setBatteryMaterialCompositions', batteryMaterialCompositions, []);
                context.commit('setBatteryMaterialList');
           
                response = await api.masterdata.batteryModels.findBatteryCategoriesByFilter('*', 0, 100);
                context.commit('setCategories', response);
            
                const name_ = await context.getters["name"];
                const pagination = await context.getters["pagination"]? context.getters["pagination"] : createDefaultAssignments.pagination.from;
                const assignments = await api.masterdata.batteryModels.findSafetyAssignmentByBatteryModelId(name_,get(pagination,"from",0),get(pagination,"pageSize",5));
                assignments.pagination.from = pagination.from;
                await context.commit('setAssignments',assignments, createDefaultAssignments());
                
            }catch(error){
                
                context.commit('setBatteryModel', createDefaultBatteryModel());

                context.commit('setBatteryMaterials', []);
                
                context.commit('setManufacturers', []);

                context.commit('setChemistries', {items:[]});

                context.commit('setChemistries', getDefaultResponse());

                context.commit('setBatteryMaterials', []);

                context.commit('setBatteryMaterialCompositions', []);

                context.commit('setCategories', getDefaultResponse());

                context.commit('setAssignments', createDefaultAssignments());
                throw error;
            }
        },

        async loadManufacturers(context) {

            try {
                let manufacturer = await api.masterdata.safetyAssignment.findByFilter('*', 0, 100);
                await context.commit('setManufacturer', manufacturer);
            } catch (error) {
                context.commit('setManufacturer', getDefaultResponse());
                throw error;
            }
        },
        async updateBatteryCategoryId(context, item){
            await context.commit('setBatteryCategoryId', item);
        },
        async updateManufacturerId(context, item){	
            await context.commit('setManufacturerId', item);
        },
        async updateBatteryChemistryId(context, item){
            await context.commit('setBatteryChemistryId', item);
        },





        async saveData(context, { name }) {

            try {

                // Assemble the payload.
                const payload = cloneDeep(context.getters.batteryModel);
                
                // Update references
                payload.references.batterycategoryId = context.getters.references.batterycategoryId;
                payload.references.manufacturerId    = context.getters.references.manufacturerId;
                payload.references.batteryChemistry  = context.getters.references.batteryChemistry;

                

                let response = await api.masterdata.batteryModels.update(payload);
                
                context.commit('setBatteryModel', get(response, 'item.batteryModel', createDefaultBatteryModel()));
            } catch (error) {
                console.log(error);
                context.commit('setBatteryModel', createDefaultBatteryModel());
                throw error;

            }
            try {
                const compositions = get(context.getters, 'batteryMaterialCompositionItems');
                let response = await api.masterdata.batteryMaterialCompositions.updateBulk(compositions, name);
                context.commit('setBatteryMaterialCompositions', response, []);

            } catch (error) {
                console.log(error);
                context.commit('setBatteryMaterialCompositions', []);
                throw error;

            }
            try {
                const model = get(context.getters, 'batteryModel');
                const assignments = get(context.getters,'assignmentItems');
                const novel = [];
                for(let element of assignments){ 
                    const payload = {
                    tenantId: model.tenantId,
                    name: "",
                    data:{custData:{}},
                    i18n:{},	
                    active: true,
                    references: {
                        safetyinstructionid: element,
                        batterymodelid: model.name
                    }
                };
                payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
                payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
                payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
                payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
                novel.push(payload);
                }
                console.log(novel);
                console.log("*****************novel**************************");
                await api.masterdata.safetyAssignment.updateBulk(novel, name);
            } catch (error) {
                console.log(error);
                context.commit('setAssignments', []);
                throw error;

            }
        },
        async updateBatteryModel(context, batteryModel) {
            await context.commit('setBatteryModel', batteryModel, createDefaultBatteryModel());
        },

        async updateBatteryWeight(context, batteryWeight) {
            await context.commit('setBatteryWeight', batteryWeight, 0);
        },

        async updateBatteryChemistry(context, batteryChemistry) {
            await context.commit('setBatteryChemistry', batteryChemistry);
        },
        async updateIdOfEuDeclerationOfConformity(context, idOfEuDeclerationOfConformity) {
            await context.commit('setIdOfEuDeclerationOfConformity', idOfEuDeclerationOfConformity, 0);
        },
        async updateEuDeclerationOfConformity(context, euDeclerationOfConformity) {
            await context.commit('setEuDeclerationOfConformity', euDeclerationOfConformity, 0);
        },

        async updateTestReports(context, testReports) {
            await context.commit('setTestReports', testReports);
        },
        async updateBatteryMaterialCompositions(context, batteryMaterialCompositionItems) {
            await context.commit('setBatteryMaterialCompositionItems', batteryMaterialCompositionItems);
        },
       
        async updateBatteryCategory(context, batteryCategory) {
            await context.commit('setBatteryCategory', batteryCategory);
            
        },
        
        async updateManufacturer(context, manufacturer) {
            await context.commit('setManufacturer', manufacturer);
           
        },
        


        async updateBatteryMaterialList(context) {
            try {
                await context.commit('setBatteryMaterialList');
            } catch (error) {
                console.log(error);
                throw error;
            }

        },
        
        
        async loadResponse(context, { from, pageSize }) {

            try {
                //
                let response = await api.masterdata.safetyAssignment.findByFilter(context.state.filter, from, pageSize);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async deleteItem(context, { name }) {
            // nimmt den Names aus der Liste
            // name in der Liste ist der Nade ist Name von der Safety Instruction
            // löschen geht nicht , weil nme  instruction != assignmnt 
            //  

                await api.masterdata.safetyAssignment.deleteById(name);
                const name_ = await context.getters["name"];
                const pagination = await context.getters["pagination"];
                const safetyAssignment = await api.masterdata.batteryModels.findSafetyAssignmentByBatteryModelId(name_,get(pagination,"from",0),get(pagination,"pageSize",5));
                safetyAssignment.pagination.from = pagination.from;
                await context.commit('setAssignments',safetyAssignment, createDefaultAssignments());
        
        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        }

    },
    getters: {
        pagination(state){
            return get(state, 'pagination',createDefaultAssignments().pagination) 
        },
        assignmentPagination(state){ 
            return get(state, 'safetyAssignments.pagination',{}) },

        assignmentItems(state) {
            let list = get(state, 'safetyAssignments.items',[])
            const novel = [];
            for(let item of list){
                novel.push(item.references.safetyinstructionid);
            }
            return novel;
        },

        assignment(state){
            return get(state, 'safetyAssignments',{pagination:{from:0,pageSize:5,totalItemCount:0},items:[]})
        },
        name(state){
            return state.batteryModel.name;
        },

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },
        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        },
        batteryModel(state) {
            return state.batteryModel;
        },

        batteryWeight(state) {
            return get(state, 'batteryModel.data.batteryWeight', 0);
        },



        idOfEuDeclerationOfConformity(state) {
            return get(state, 'batteryModel.data.idOfEuDeclerationOfConformity');
        },

        euDeclerationOfConformity(state) {
            return get(state, 'batteryModel.data.euDeclerationOfConformity');
        },
        testReports(state) {
            const originalTestReports = get(state, 'batteryModel.data.testReports', []);
            return originalTestReports.map(report => ({ value: report }));
        },
        batteryMaterialCompositions(state) {

            return get(state, 'batteryMaterialCompositions', []);
        },
        batteryMaterialCompositionItems(state) {
            return get(state, 'batteryMaterialCompositions.items', []);
        },

        availableBatteryMaterials(state) {
            return state.availableBatteryMaterials;
        },

        batteryCategories(state) {
            return state.batteryCategories ? state.batteryCategories : getDefaultResponse();
        },
        batteryCategory(state) {
            return get(state, 'batteryModel.references.batterycategoryId');
        },
        
        manufacturer(state) {
            return get(state, 'model.manufacturer', getDefaultItemResponse());
        },

        manufacturers(state) {
            return get(state, 'context.manufacturers.items', []);
        },

        batteryChemistry(state) {
            return get(state, 'model.batteryChemistry', getDefaultItemResponse());
        },
        batteryCategoryId(state){
            return get(state, 'model.batterycategoryId');
        },
        manufacturerId(state){
            return get(state, 'model.manufacturerId');
        },
        batteryChemistries(state){
            return get(state, 'context.chemistries.items')
        },
        references(state) {
            return get(state, 'model', {});
        }

    }

}

    function createDefaultAssignments() {
        return {
            pagination: {
                from: 0,
                pageSize: 5,
                totalItemCount: 5
            },
            items: []
        };
    }

function getDefaultFilter() {
    return '*';
}

function createDefaultBatteryModel() {

    return {
        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "batteryWeight": "",
            "idOfEuDeclerationOfConformity": null,
            "euDeclerationOfConformity": null,
            "testReports": []
        },
        "active": true,
        "references": {
            "batterycategoryId": null,
            "manufacturerId": null,
            "batteryChemistry": null
        },
    }
}

function getDefaultItemResponse() {

    return {
        tenantId: null,
        name: null,
        active: true,
        data: {},
        i18n: {},
        references: {}
    }
}

function getDefaultResponse() {
    return {
        tenantId: "",
        items: [],
        pagination: {
            from: 0,
            pageSize: 5,
            totalItemCount: 5
        }
    };
}




import Configuration from '@/utils/configuration';

import { axios } from '@/store/axios';

const BACKEND_HOST = Configuration.value('BACKEND_HOST');
const BACKEND_PORT = Configuration.value('BACKEND_PORT');
const TENANT_ID = Configuration.value('TENANT_ID');
const API_VERSION = 'v2';
const BACKENDROOT = BACKEND_HOST + (BACKEND_PORT ? (':' + BACKEND_PORT) : '');
const BACKEND = BACKENDROOT + '/' + API_VERSION + '/' + TENANT_ID;
const TIMEOUT = 6000;

import { isEmpty } from 'lodash';
export default {



    masterdata: {

        batteryStatusMatrix: {

            get: (batteryModels, batteryStatus, from, pageSize) => {
                const url = BACKEND + '/battery-data-hub/masterdata/battery-status-matrix';

                const params = {
                    from: from, pageSize: pageSize
                };
                
                if (!isEmpty(batteryModels)) params['batteryModels'] = batteryModels.join(',');
                if (!isEmpty(batteryStatus)) params['batteryStatus'] = batteryStatus.join(',');

                return axios.get(url, { timeout: TIMEOUT, params: params })
                            .then(response => {
                                return response.data;
                            }).catch(error => {
                                return Promise.reject(error);
                            });
                
            }
        },

        batteryMaterialMatrix: {

            get: (batteryModels, batteryMaterials, manufacturers, from, pageSize) => {
                const url = BACKEND + '/battery-data-hub/masterdata/battery-material-matrix';

                const params = {
                    from: from, pageSize: pageSize
                };
                
                if (!isEmpty(batteryModels)) params['batteryModels'] = batteryModels.join(',');
                if (!isEmpty(batteryMaterials)) params['batteryMaterials'] = batteryMaterials.join(',');
                if (!isEmpty(manufacturers)) params['manufacturers'] = manufacturers.join(',');

                return axios.get(url, { timeout: TIMEOUT, params: params })
                            .then(response => {
                                return response.data;
                            }).catch(error => {
                                return Promise.reject(error);
                            });
                
            }
        },

        /***
        * The endpoint for managing sites.
        * @since DXS 3.4.0
        */
        substancesRecyclingShares: {

            create: (request, name) => {
                let url = BACKEND + '/battery-data-hub/masterdata/substance-recycling-shares/';


                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    name;
                    return Promise.reject(error);
                });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/substance-recycling-shares/" + request.name;
                console.log(url)
                console.log("api has been called.")
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/substance-recycling-shares/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            findByModel: (name) => {

                let url = BACKEND + '/battery-data-hub/masterdata/substance-recycling-shares/reference/battery-model/' + name;
                const filter = "*";
                const from = 0;
                const pageSize = 500;
                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(substanceId) {
                let url = BACKEND + '/battery-data-hub/masterdata/substance-recycling-shares/' + substanceId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(substanceId) {
                let url = BACKEND + '/battery-data-hub/masterdata/substance-recycling-shares/' + substanceId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batterySubstances: {


            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-substances/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-substances/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/battery-substances/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(batterySubstanceId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-substances/' + batterySubstanceId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batterySubstanceId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-substances/' + batterySubstanceId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        hazardousSubstanceClassifications: {


            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/hazardous-substance-classifications/" + request.name;
                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/hazardous-substance-classifications/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/hazardous-substance-classifications/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(hazardousSubstanceClassification) {
                let url = BACKEND + '/battery-data-hub/masterdata/hazardous-substance-classifications/' + hazardousSubstanceClassification;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(hazardousSubstanceClassificationId) {
                let url = BACKEND + '/battery-data-hub/masterdata/hazardous-substance-classifications/' + hazardousSubstanceClassificationId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        safetyInstructions: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/safety-instructions/" + request.name;
                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/safety-instructions/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/safety-instructions/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(safetyInstructions) {
                let url = BACKEND + '/battery-data-hub/masterdata/safety-instructions/' + safetyInstructions;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(safetyInstructions) {
                let url = BACKEND + '/battery-data-hub/masterdata/safety-instructions/' + safetyInstructions;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryMaterials: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-materials/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-materials/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-materials/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByBatteryModel: (batteryModelId, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-materials/references/battery-model/' + batteryModelId;

                return axios.get(url, { timeout: TIMEOUT, params: { from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryMaterialId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-materials/' + batteryMaterialId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryMaterialId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-materials/' + batteryMaterialId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryModels: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-models/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-models/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-models/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            findBatteryModelAgregationByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-model-aggregation/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            getBatteryMaterialMatrixByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-material-matrix/';

                return axios.post(url, filter, from, pageSize)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findBatteryCategoriesByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-categories/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findCompositionByBatteryModelId: (batteryModelId, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/battery-material-composition/references/batteryModel/' + batteryModelId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: batteryModelId, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAllBatteryMaterialCompositions: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/battery-material-composition/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            findSafetyAssignmentByBatteryModelId: (batteryModelId, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/safety-instruction-assignment/references/batteryModel/' + batteryModelId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: batteryModelId, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAllSafetyAssignments: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/safety-instruction-assignment/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryModelId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-models/' + batteryModelId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryMaterialId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-models/' + batteryMaterialId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryMaterialCompositions: {
            updateBulk: (request, name) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-material-composition/bulk/' + name;


                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-material-composition/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByBatteryModel(batteryModelId, from, pageSize)  {
                let url = BACKEND + `/battery-data-hub/masterdata/battery-material-composition/references/batteryModel/${batteryModelId}`;

                return axios.get(url, { timeout: TIMEOUT, params: { from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        safetyAssignment: {
            deleteById(assignmentId) {
                let url = BACKEND + '/battery-data-hub/masterdata/safety-instruction-assignment/' + assignmentId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            updateBulk: (request, name) => {
                let url = BACKEND + '/battery-data-hub/masterdata/safety-instruction-assignment/bulk/' + name;


                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/safety-instruction-assignment/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByBatteryModel(batteryModelId, from, pageSize)  {
                let url = BACKEND + `/battery-data-hub/masterdata/safety-instruction-assignment/references/batteryModel/${batteryModelId}`;

                return axios.get(url, { timeout: TIMEOUT, params: { from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryCategories: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-categories/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
            
            update(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-categories/" + request.name;

                return axios
                    .put(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },



            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-categories/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-categories/' + batteryCategoryId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-categories/' + batteryCategoryId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryChemistries: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-chemistries/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
            
            update(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-chemistries/" + request.name;

                return axios
                    .put(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },



            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-chemistries/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryChemistryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-chemistries/' + batteryChemistryId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryChemistryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-chemistries/' + batteryChemistryId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        manufacturers: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/manufacturers/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/manufacturers/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturers/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturers/' + batteryCategoryId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturers/' + batteryCategoryId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        manufacturerplant: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/manufacturer-plants/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/manufacturer-plants/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturer-plants/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },


            findById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturer-plants/' + batteryCategoryId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryCategoryId) {
                let url = BACKEND + '/battery-data-hub/masterdata/manufacturer-plants/' + batteryCategoryId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        batteryStatus: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-status/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-status/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/battery-status/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(batteryStatusId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-status/' + batteryStatusId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryStatusId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-status/' + batteryStatusId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        severity: {

            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/severity/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/severity/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/severity/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(batteryStatusId) {
                let url = BACKEND + '/battery-data-hub/masterdata/severity/' + batteryStatusId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryStatusId) {
                let url = BACKEND + '/battery-data-hub/masterdata/severity/' + batteryStatusId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        
        batteryPacks: {
            create(request) {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-packs/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/battery-data-hub/masterdata/battery-packs/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/battery-data-hub/masterdata/battery-packs/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(batteryPackId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-packs/' + batteryPackId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(batteryPackId) {
                let url = BACKEND + '/battery-data-hub/masterdata/battery-packs/' + batteryPackId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        }
    }

}

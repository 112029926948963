<template>
    <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
        <v-col cols="12" lg="9">
          <c-base-dialog
            :value="shift-events-guard"
            :title="$t('shift-events-guard-wizard-guard.view.title')"
            :subtitle="$t('shift-events-guard-wizard-guard.view.subtitle')"
            color="secondary"
            @dialog-saved="save"
            @dialog-canceled="cancel"
          >
            <template v-slot:default="">
              <v-card flat>
                <v-card-text>
                  <c-item-selector :items="sites.items" v-model="siteId" :return-object="false" filled
                      :chips="false" :label="$t('workplace-wizard.view.subheadder.sites')"
                      :rules="[v => v && !!v.trim() || $t('workplace-wizard.view.siteId.validation.notEmpty')]" />                  
                </v-card-text>
              </v-card>
            </template>
          </c-base-dialog>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import shiftEventWizardGuardModule from "../../../store/apps/shift-event-log/shift-event-wizard-guard.module";
  
  const MODULE_NAME = "shiftEventWizardGuard";
  
  export default {
    name: "shift-event-log-wizard-guard",
  
    inject: [
      "errorHandlerService",
      "progressIndicatorService",
      "envContextService",
    ],
  
    methods: {
      async save() {
        this.progressIndicatorService.show();
  
        try {
          this.$router.push({
                name: "ShiftEventLogWizardView",
                params: {
                    siteId: this.siteId,
                },
            });
        } catch (error) {
          this.errorHandlerService.handleError(error);
        } finally {
          this.progressIndicatorService.hide();
        }
      },
  
      cancel() {
        this.$router.push({
          name: "ShiftEventLogListView",
        });
      },
  
      setTenantId(tenantId) {
        this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
      },
  
      reset() {
        this.$store.dispatch(MODULE_NAME + "/resetShiftEventGuard");
        this.setTenantId(this.tenantId);
      },
    },
  
    computed: {
      shiftEventGuard: {
        get() {
          return this.$store.getters[MODULE_NAME + "/shift-events-guard"];
        },
  
        set(item) {
          this.$store
            .dispatch(MODULE_NAME + "/updateShiftEventGuard", item)
            .catch(this.errorHandlerService.handleError);
        },
      },

      sites() {
          return this.$store.getters[MODULE_NAME + "/sites"];
      },

      siteId: {
          get() {
              return this.$store.getters[MODULE_NAME + "/siteId"];
          },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateSiteId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
      
      tenantId() {
          return this.envContextService.tenantId;
      }
  
    },
  
    created() {
      if (!this.$store.hasModule(MODULE_NAME)) {
        this.$store.registerModule(MODULE_NAME, shiftEventWizardGuardModule);
      }
  
      this.reset();
    },
  };
  </script>
  
  <i18n>
  {
      "en" : {
          "shift-events-guard-wizard-guard.view.title" : "Create New Shift Event | Select a Site",
          "workplace-wizard.view.subheadder.sites" : "Site",
          "shift-events-guard-wizard-guard.view.subtitle" : "This wizard will let you choose a Site, to create the Shift Event.",
          "workplace-wizard.view.siteId.validation.notEmpty": "Please assign a Site."
      },
  
      "de" : {
          "shift-events-guard-wizard-guard.view.title" : "Neue Schichtmeldung anlegen | Standort auswählen",
          "workplace-wizard.view.subheadder.sites" : "Produktionsstandort",
          "shift-events-guard-wizard-guard.view.subtitle" : "Dieser Assistent lässt sie den Standort auswählen, an dem Schichtmeldung erstellt werden soll.",
          "workplace-wizard.view.siteId.validation.notEmpty": "Bitte wählen sie einen Produktionsstandort aus."
      }
  }
  </i18n>
  
  
  

// ===========================================================================================================================
// APPS
// ===========================================================================================================================
//import ShiftEventEditorView from '@/core/views/temp/shift-event-editor.view';

import SitesListView from '@/solutions/smart-factory-services/views/masterdata/sites/sites-list.view';
import SitesEditorView from '@/solutions/smart-factory-services/views/masterdata/sites/sites-editor.view';
import SitesWizardView from '@/solutions/smart-factory-services/views/masterdata/sites/sites-wizard.view';


import ShiftEventTypesListView from '@/solutions/smart-factory-services/views/masterdata/shift-event-types/shift-event-types-list.view';
import ShiftEventTypesEditorView from '@/solutions/smart-factory-services/views/masterdata/shift-event-types/shift-event-types-editor.view';
import ShiftEventTypesWizardView from '@/solutions/smart-factory-services/views/masterdata/shift-event-types/shift-event-types-wizard.view';


import ShiftEventLogWizardGuardView from '@/solutions/smart-factory-services/views/apps/shift-event-log/shift-event-wizard-guard.view';
import ShiftEventLogWizardView from '@/solutions/smart-factory-services/views/apps/shift-event-log/shift-event-log-wizard.view';
import ShiftEventLogListView from '@/solutions/smart-factory-services/views/apps/shift-event-log/shift-event-log-list.view';
import ShiftEventLogDashboardView from '@/solutions/smart-factory-services/views/apps/shift-event-log/shift-event-log-dashboard.view';


import WorkplaceListView from '@/solutions/smart-factory-services/views/masterdata/workplaces/workplace-list.view';
import WorkplaceEditorView from '@/solutions/smart-factory-services/views/masterdata/workplaces/workplace-editor.view';
import WorkplaceWizardView from '@/solutions/smart-factory-services/views/masterdata/workplaces/workplace-wizard.view';
import WorkplaceTypeListView from '@/solutions/smart-factory-services/views/masterdata/workplace-types/workplace-types-list.view';
import WorkplaceTypesEditorView from '@/solutions/smart-factory-services/views/masterdata/workplace-types/workplace-types-editor.view';
import WorkplaceTypesWizardView from '@/solutions/smart-factory-services/views/masterdata/workplace-types/workplace-types-wizard.view';



import Roles from '@/roles';

export default [

    // =======================================================================
    // APPS
    // =======================================================================
    {
        path: '/solutions/smart-factory-service/apps/shift-event-log',
        name: 'ShiftEventLogListView',
        component: ShiftEventLogListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-apps',
                icon: 'note_alt',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Shift Event Log'
                    },

                    'de': {
                        name: 'Schichtbuch'
                    }
                }
            },

            authorization: {

                roles: [Roles.SHIFTEVENTS_READ]
            }
        }
    },
    {
        path: '/solutions/smart-factory-service/apps/shift-event-log/:name/dashboard',
        name: 'ShiftEventLogDashboardView',
        component: ShiftEventLogDashboardView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            
            navigation: {

                i18n: {

                    'en': {

                        name: 'Shift Event Log'
                    },

                    'de': {
                        name: 'Schichtbuch'
                    }
                }
            },

            authorization: {

                roles: [Roles.SHIFTEVENTS_READ]
            }
        }
        

    },
    {
        path: '/solutions/smart-factory-service/apps/shift-event-log/new',
        name: 'ShiftEventWizardGuardView',
        component: ShiftEventLogWizardGuardView,

        meta: {
            
            navigation: {
                
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create new Shift Event'
                    },

                    'de': {
                        name: 'Neue Schichtmeldung anlegen'
                    }
                }
            },

            authorization: {

                roles: [Roles.SHIFTEVENTS_CREATE]
            }
        }

    },
    {
        path: '/solutions/smart-factory-service/apps/shift-event-log/:siteId/events/new',
        name: 'ShiftEventLogWizardView',
        component: ShiftEventLogWizardView,
        props: (route) => ({ value: { name: route.params.siteId } }),

        meta: {

            authorization: {

                roles: [Roles.SHIFTEVENTS_CREATE]
            }
        }

    },

    // =======================================================================
    // MASTERDATA
    // =======================================================================
    {
        path: '/solutions/smart-factory-services/masterdata/sites',
        name: 'SitesListView',
        component: SitesListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-masterdata',
                icon: 'factory',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Sites'
                    },

                    'de': {
                        name: 'Produktionsstandorte'
                    }
                }
            },

            authorization: {

                roles: [Roles.SITES_READ]
            }
        }
    },


    // ============================= SITES
    {
        path: '/solutions/smart-factory-services/masterdata/sites/new',
        name: 'SitesWizardView',
        component: SitesWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create new Site'
                    },

                    'de': {
                        name: 'Neuen Produktionsstandort anlegen'
                    }
                }
            },

            authorization: {

                roles: [Roles.SITES_CREATE]
            }
        }
    },

    {
        path: '/solutions/smart-factory-services/masterdata/sites/:name',
        name: 'SiteEditorView',
        component: SitesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Site Details'
                    },

                    'de': {
                        name: 'Produktionsstandort Detailansicht'
                    }
                }
            },

            authorization: {

                roles: [Roles.SITES_READ]
            }
        }
    },

            // ============================= SHIFT EVENTS (OBSOLETE)
        /*
    {
        path: '/solutions/smart-factory-services/masterdata/shift-events',
        name: 'ShiftEventListView',
        component: ShiftEventsListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-masterdata',
                icon: 'factory',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Shift-Events'
                    },

                    'de': {
                        name: 'Schichtmeldungen'
                    }
                }
            },
        }
    },


    {
        path: '/solutions/smart-factory-services/masterdata/shift-events/:name',
        name: 'ShiftEventEditorView',
        component: ShiftEventsEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Shift-Event Details'
                    },

                    'de': {
                        name: 'Schichtmeldungen Detailansicht'
                    }
                }
            },
        }
    },

    {
        path: '/solutions/smart-factory-services/masterdata/shift-events/new',
        name: 'ShiftEventWizardView',
        component: ShiftEventsWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create new Shift-Event'
                    },

                    'de': {
                        name: 'Neue Schichtmeldung anlegen'
                    }
                }
            },

            authorization: {

                roles: [Roles.SHIFTEVENT_CREATE]
            }
        }
    },
*/

    // ============================= SHIFT EVENT TYPES 
    {
        path: '/solutions/smart-factory-services/masterdata/shift-event-types',
        name: 'ShiftEventTypesListView',
        component: ShiftEventTypesListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-masterdata',
                icon: 'factory',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Shift Event Types'
                    },

                    'de': {
                        name: 'Schichtmeldungsarten'
                    }
                }
            },
            authorization: {

                roles: [Roles.SHIFTEVENTTYPE_READ]
            }
        }
    },
    

    {
        path: '/solutions/smart-factory-services/masterdata/shift-events-types/new',
        name: 'ShiftEventTypesWizardView',
        component: ShiftEventTypesWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create new Shift Event Types'
                    },

                    'de': {
                        name: 'Neue Schichtmeldungsart anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.SHIFTEVENTTYPE_CREATE]
            }
        }
    },

    {
        path: '/solutions/smart-factory-services/masterdata/shift-event-types/:name',
        name: 'ShiftEventTypesEditorView',
        component: ShiftEventTypesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Shift Event Types Details'
                    },

                    'de': {
                        name: 'Schichtmeldungsarten Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.SHIFTEVENTTYPE_READ]
            }
        }
    },

        // ============================= WORKPLACE TYPES 
    {
        path: '/solutions/smart-factory-services/masterdata/workplace-types',
        name: 'WorkplaceTypeListView',
        component: WorkplaceTypeListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-masterdata',
                icon: 'factory',
                order: 220,

                i18n: {

                    'en': {

                        name: 'Workplace Types'
                    },

                    'de': {
                        name: 'Arbeitsplatztypen'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACETYPES_READ]
            }
        }
    },


    {
        path: '/solutions/smart-factory-services/masterdata/workplace-types/new',
        name: 'WorkplaceTypesWizardView',
        component: WorkplaceTypesWizardView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create a new Workplace Type'
                    },

                    'de': {
                        name: 'Einen neuen Arbeitsplatztypen anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACETYPES_CREATE]
            }
        }
    },

    {
        path: '/solutions/smart-factory-services/masterdata/workplace-types/:name',
        name: 'WorkplaceTypesEditorView',
        component: WorkplaceTypesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Workplacetype Details'
                    },

                    'de': {
                        name: 'Arbeitsplatztyp Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACETYPES_READ]
            }
        }
    },

    // ============================= WORKPLACES
    {
        path: '/solutions/smart-factory-services/masterdata/workplaces',
        name: 'WorkplaceListView',
        component: WorkplaceListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-smartfactoryservices-masterdata',
                icon: 'factory',
                order: 230,

                i18n: {

                    'en': {

                        name: 'Workplaces'
                    },

                    'de': {
                        name: 'Arbeitsplätze'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACE_READ]
            }
        }
    },
    {
        path: '/solutions/smart-factory-services/masterdata/workplaces/new',
        name: 'WorkplaceWizardView',
        component: WorkplaceWizardView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create a new Workplace'
                    },

                    'de': {
                        name: 'Einen neuen Arbeitsplatz anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACE_CREATE]
            }
        }
    },
    {
        path: '/solutions/smart-factory-services/masterdata/workplaces/:name',
        name: 'WorkplaceEditorView',
        component: WorkplaceEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Workplace Details'
                    },

                    'de': {
                        name: 'Arbeitsplatz Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.WORKPLACE_READ]
            }
            
        }}
            
];


import api from '../../../api/smart-factory.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        shiftEvent: createEmptyShiftEvent(),
        tenantId: null,
        siteId: ""
    },

    mutations: {

        setShiftEvent(state, shiftEvent) {

            if (shiftEvent) {

                state.shiftEvent.tenantId = shiftEvent.tenantId ? shiftEvent.tenantId : null;
                state.shiftEvent.name = shiftEvent.name ? shiftEvent.name : null;
                state.shiftEvent.i18n = shiftEvent.i18n ? shiftEvent.i18n : {};
                state.shiftEvent.data = shiftEvent.data ? shiftEvent.data : { custData: {} };
                state.shiftEvent.active = shiftEvent.active ? shiftEvent.active : true;
                state.shiftEvent.references = shiftEvent.references ? shiftEvent.references : {};

            } else {
                state.shiftEvent.tenantId = null;
                state.shiftEvent.name = null;
                state.shiftEvent.i18n = null;
                state.shiftEvent.data = null;
                state.shiftEvent.active = null;
                state.shiftEvent.references = null;
            }

        },

        setSiteId(state, siteId) {
            state.siteId = siteId;
        },

        setSites(state, sites) {
            if (sites) {

                let items = [];
                if (sites.items) {
                    state.sites.items = sites.items;
                } else {
                    state.sites.items = items;
                }

                if (sites.tenantId) {
                    state.sites.tenantId = sites.tenantId;
                }

                if (sites.pagination) {
                    state.sites.pagination = sites.pagination;
                }

            }
        },



        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {
        
        async resetShiftEvent(context) {
            context.commit('setShiftEvent', createEmptyShiftEvent());
        },

        async updateShiftEvent(context, shiftEvent) {

            try {
                context.commit('setShiftEvent', shiftEvent);
            } catch (error) {
                return Promise.reject(error);
            }
        },


        async loadData(context) {

            try {
                let sites = await api.masterdata.sites.findByFilter('*', 0, 100);
                context.commit('setSites', sites);
            } catch (error) {
                context.commit('setSites', []);
                throw error;
            }

        },
          async updateSiteId(context, siteId) {
            try {
                context.commit('setSiteId', siteId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.shiftEvent;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
     sites(state) {
            return state.sites ? state.sites : [];
        },
        shiftEvent(state) {
            return state.shiftEvent ? state.shiftEvent : createEmptyShiftEvent();
        },
        siteId(state) {
            return state.siteId ? state.siteId : null;
        }

}
}

function createEmptyShiftEvent() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}